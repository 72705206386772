import { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
import { BASE_PATH, BaseAPI, operationServerMap, RequestArgs } from '../base';
import globalAxios from 'axios';
import {
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../common';
import { BaseResponseSearchRes } from './types';

interface SearchParams {
  q?: string;
  tag?: string;
  lang?: string;
}

/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 검색 결과를 조회합니다.
     * @summary 검색 결과 조회
     * @param {SearchParams} params 검색 파라미터
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearch: async (
      params: SearchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/search`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (params.q !== undefined) {
        localVarQueryParameter['q'] = params.q;
      }
      if (params.tag !== undefined) {
        localVarQueryParameter['tag'] = params.tag;
      }
      if (params.lang !== undefined) {
        localVarQueryParameter['lang'] = params.lang;
      }

      // authentication JWT required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SearchControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * 검색 결과를 조회합니다.
     * @summary 검색 결과 조회
     * @param {SearchParams} params 검색 파라미터
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSearch(
      params: SearchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseSearchRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSearch(
        params,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SearchControllerApi.getSearch']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SearchControllerApiFp(configuration);
  return {
    /**
     * 검색 결과를 조회합니다.
     * @summary 검색 결과 조회
     * @param {SearchParams} params 검색 파라미터
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearch(
      params: SearchParams,
      options?: any,
    ): AxiosPromise<BaseResponseSearchRes> {
      return localVarFp
        .getSearch(params, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
  /**
   * 검색 결과를 조회합니다.
   * @summary 검색 결과 조회
   * @param {SearchParams} params 검색 파라미터
   * @throws {RequiredError}
   * @memberof SearchControllerApi
   */
  public getSearch(params: SearchParams) {
    return SearchControllerApiFp(this.configuration)
      .getSearch(params)
      .then((request) => request(this.axios, this.basePath));
  }
}
