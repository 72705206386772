import { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
import { BASE_PATH, BaseAPI, operationServerMap, RequestArgs } from '../base';
import globalAxios from 'axios';

import {
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../common';
import { BaseResponseGetEventDetailRes } from './types';

/**
 * EventDetailControllerApi - axios parameter creator
 * @export
 */
export const EventDetailControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 이벤트 상세 정보를 조회합니다.
     * @summary 이벤트 상세 조회
     * @param {number} id 이벤트 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventDetail: async (
      id: number,
      lang?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined.');
      }
      const localVarPath = `/api/events/${id}`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventDetailControllerApi - functional programming interface
 * @export
 */
export const EventDetailControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    EventDetailControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * 이벤트 상세 정보를 조회합니다.
     * @summary 이벤트 상세 조회
     * @param {number} id 이벤트 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventDetail(
      id: number,
      lang?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetEventDetailRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventDetail(
        id,
        lang,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['EventDetailControllerApi.getEventDetail']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * EventDetailControllerApi - factory interface
 * @export
 */
export const EventDetailControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EventDetailControllerApiFp(configuration);
  return {
    /**
     * 이벤트 상세 정보를 조회합니다.
     * @summary 이벤트 상세 조회
     * @param {number} id 이벤트 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventDetail(
      id: number,
      lang?: string,
      options?: any,
    ): AxiosPromise<BaseResponseGetEventDetailRes> {
      return localVarFp
        .getEventDetail(id, lang, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventDetailControllerApi - object-oriented interface
 * @export
 * @class EventDetailControllerApi
 * @extends {BaseAPI}
 */
export class EventDetailControllerApi extends BaseAPI {
  /**
   * 이벤트 상세 정보를 조회합니다.
   * @summary 이벤트 상세 조회
   * @param {number} id 이벤트 ID
   * @param {string} [lang] 언어 코드
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventDetailControllerApi
   */
  public getEventDetail(id: number, lang?: string) {
    return EventDetailControllerApiFp(this.configuration)
      .getEventDetail(id, lang)
      .then((request) => request(this.axios, this.basePath));
  }
}
