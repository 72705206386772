import axios from 'axios';
import { Configuration } from './configuration';
import {
  HealthControllerApiFactory,
  PaymentsApiFactory,
  TestApiFactory,
  UserControllerApiFactory,
} from './api';
import { HeaderControllerApiFactory } from './header/headerApi';
import { FooterControllerApiFactory } from './footer/footerApi';
import { LeadershipControllerApiFactory } from './leadership/leadershipApi';
import { GlobalNetworkControllerApiFactory } from './global-network/globalNetworkApi';
import { PopupControllerApiFactory } from './popup/popupApi';
import { NewsControllerApiFactory } from './news/newsApi';
import { EventControllerApiFactory } from './event/eventApi';
import { SearchControllerApiFactory } from './search/searchApi';
import { TagControllerApiFactory } from './tag/tagApi';
import { NewsDetailControllerApiFactory } from './news/newsDetailApi';
import { EventDetailControllerApiFactory } from './event/eventDetailApi';

export const API_ADDRESS =
  process.env.RELEASE_ENV === 'production'
    ? process.env.NEXT_PUBLIC_API_URL
    : process.env.NEXT_PUBLIC_API_URL;

class APIClientClass {
  public client = axios.create({
    withCredentials: true,
    timeout: 10000,
  });
  private readonly configuration = new Configuration();

  readonly Payments = PaymentsApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Test = TestApiFactory(this.configuration, API_ADDRESS, this.client);
  readonly User = UserControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Health = HealthControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Header = HeaderControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Footer = FooterControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Leadership = LeadershipControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly GlobalNetwork = GlobalNetworkControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Popup = PopupControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly News = NewsControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Event = EventControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Search = SearchControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly Tags = TagControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly NewsDetail = NewsDetailControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
  readonly EventDetail = EventDetailControllerApiFactory(
    this.configuration,
    API_ADDRESS,
    this.client,
  );
}

export const APIClient = new APIClientClass();
