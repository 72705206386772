import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import all translation files
import commonEN from '@/locales/en/common.json';
import homeEN from '@/locales/en/home.json';
import companyEN from '@/locales/en/company.json';
import marketEN from '@/locales/en/market.json';
import productEN from '@/locales/en/product.json';

import commonKO from '@/locales/ko/common.json';
import homeKO from '@/locales/ko/home.json';
import companyKO from '@/locales/ko/company.json';
import marketKO from '@/locales/ko/market.json';
import productKO from '@/locales/ko/product.json';

const i18n = createInstance();

const resources = {
  en: {
    common: commonEN,
    home: homeEN,
    company: companyEN,
    market: marketEN,
    product: productEN,
  },
  ko: {
    common: commonKO,
    home: homeKO,
    company: companyKO,
    market: marketKO,
    product: productKO,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  ns: ['common', 'home', 'company', 'market', 'product'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  detection: {
    order: ['path', 'navigator'],
  },
  load: 'languageOnly',
});

export default i18n;
