import { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
import { BASE_PATH, BaseAPI, operationServerMap, RequestArgs } from '../base';

import globalAxios from 'axios';

import {
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../common';
import { BaseResponseGetGlobalNetworkRes } from './types';

/**
 * GlobalNetworkControllerApi - axios parameter creator
 * @export
 */
export const GlobalNetworkControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 글로벌 네트워크 정보를 조회합니다.
     * @summary 글로벌 네트워크 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGlobalNetwork: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/company/global-network`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;

      // authentication JWT required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GlobalNetworkControllerApi - functional programming interface
 * @export
 */
export const GlobalNetworkControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    GlobalNetworkControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * 글로벌 네트워크 정보를 조회합니다.
     * @summary 글로벌 네트워크 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGlobalNetwork(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetGlobalNetworkRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getGlobalNetwork(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['GlobalNetworkControllerApi.getGlobalNetwork']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * GlobalNetworkControllerApi - factory interface
 * @export
 */
export const GlobalNetworkControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GlobalNetworkControllerApiFp(configuration);
  return {
    /**
     * 글로벌 네트워크 정보를 조회합니다.
     * @summary 글로벌 네트워크 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGlobalNetwork(
      options?: any,
    ): AxiosPromise<BaseResponseGetGlobalNetworkRes> {
      return localVarFp
        .getGlobalNetwork(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GlobalNetworkControllerApi - object-oriented interface
 * @export
 * @class GlobalNetworkControllerApi
 * @extends {BaseAPI}
 */
export class GlobalNetworkControllerApi extends BaseAPI {
  /**
   * 글로벌 네트워크 정보를 조회합니다.
   * @summary 글로벌 네트워크 조회
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GlobalNetworkControllerApi
   */
  public getGlobalNetwork() {
    return GlobalNetworkControllerApiFp(this.configuration)
      .getGlobalNetwork()
      .then((request) => request(this.axios, this.basePath));
  }
}
