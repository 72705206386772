import { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
import { BASE_PATH, BaseAPI, operationServerMap, RequestArgs } from '../base';

import globalAxios from 'axios';

import {
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../common';
import { BaseResponseGetPopupRes } from './types';

/**
 * PopupControllerApi - axios parameter creator
 * @export
 */
export const PopupControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 팝업 정보를 조회합니다.
     * @summary 팝업 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopup: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/popup`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;

      // authentication JWT required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PopupControllerApi - functional programming interface
 * @export
 */
export const PopupControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PopupControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * 팝업 정보를 조회합니다.
     * @summary 팝업 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPopup(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetPopupRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPopup(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PopupControllerApi.getPopup']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PopupControllerApi - factory interface
 * @export
 */
export const PopupControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PopupControllerApiFp(configuration);
  return {
    /**
     * 팝업 정보를 조회합니다.
     * @summary 팝업 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopup(options?: any): AxiosPromise<BaseResponseGetPopupRes> {
      return localVarFp
        .getPopup(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PopupControllerApi - object-oriented interface
 * @export
 * @class PopupControllerApi
 * @extends {BaseAPI}
 */
export class PopupControllerApi extends BaseAPI {
  /**
   * 팝업 정보를 조회합니다.
   * @summary 팝업 조회
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PopupControllerApi
   */
  public getPopup() {
    return PopupControllerApiFp(this.configuration)
      .getPopup()
      .then((request) => request(this.axios, this.basePath));
  }
}
