import { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
import { BASE_PATH, BaseAPI, operationServerMap, RequestArgs } from '../base';
import globalAxios from 'axios';

import {
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
  createRequestFunction,
} from '../common';
import { BaseResponseGetNewsDetailRes } from './types';

/**
 * NewsDetailControllerApi - axios parameter creator
 * @export
 */
export const NewsDetailControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 뉴스 상세 정보를 조회합니다.
     * @summary 뉴스 상세 조회
     * @param {number} id 뉴스 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsDetail: async (
      id: number,
      lang?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined.');
      }
      const localVarPath = `/api/news/${id}`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsDetailControllerApi - functional programming interface
 * @export
 */
export const NewsDetailControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    NewsDetailControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * 뉴스 상세 정보를 조회합니다.
     * @summary 뉴스 상세 조회
     * @param {number} id 뉴스 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewsDetail(
      id: number,
      lang?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetNewsDetailRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsDetail(
        id,
        lang,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NewsDetailControllerApi.getNewsDetail']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NewsDetailControllerApi - factory interface
 * @export
 */
export const NewsDetailControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NewsDetailControllerApiFp(configuration);
  return {
    /**
     * 뉴스 상세 정보를 조회합니다.
     * @summary 뉴스 상세 조회
     * @param {number} id 뉴스 ID
     * @param {string} [lang] 언어 코드
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsDetail(
      id: number,
      lang?: string,
      options?: any,
    ): AxiosPromise<BaseResponseGetNewsDetailRes> {
      return localVarFp
        .getNewsDetail(id, lang, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsDetailControllerApi - object-oriented interface
 * @export
 * @class NewsDetailControllerApi
 * @extends {BaseAPI}
 */
export class NewsDetailControllerApi extends BaseAPI {
  /**
   * 뉴스 상세 정보를 조회합니다.
   * @summary 뉴스 상세 조회
   * @param {number} id 뉴스 ID
   * @param {string} [lang] 언어 코드
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsDetailControllerApi
   */
  public getNewsDetail(id: number, lang?: string) {
    return NewsDetailControllerApiFp(this.configuration)
      .getNewsDetail(id, lang)
      .then((request) => request(this.axios, this.basePath));
  }
}
