/* tslint:disable */
/* eslint-disable */
/**
 * demo 서비스 API v1
 * demo 서비스 API v1
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base';

/**
 *
 * @export
 * @interface BaseResponseGetSocialOAuthRes
 */
export interface BaseResponseGetSocialOAuthRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponseGetSocialOAuthRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponseGetSocialOAuthRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseGetSocialOAuthRes
   */
  message?: string;
  /**
   *
   * @type {GetSocialOAuthRes}
   * @memberof BaseResponseGetSocialOAuthRes
   */
  result?: GetSocialOAuthRes;
}
/**
 *
 * @export
 * @interface BaseResponseGetUserRes
 */
export interface BaseResponseGetUserRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponseGetUserRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponseGetUserRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseGetUserRes
   */
  message?: string;
  /**
   *
   * @type {GetUserRes}
   * @memberof BaseResponseGetUserRes
   */
  result?: GetUserRes;
}
/**
 *
 * @export
 * @interface BaseResponseListGetMemoDto
 */
export interface BaseResponseListGetMemoDto {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponseListGetMemoDto
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponseListGetMemoDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListGetMemoDto
   */
  message?: string;
  /**
   *
   * @type {Array<GetMemoDto>}
   * @memberof BaseResponseListGetMemoDto
   */
  result?: Array<GetMemoDto>;
}
/**
 *
 * @export
 * @interface BaseResponseListGetUserRes
 */
export interface BaseResponseListGetUserRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponseListGetUserRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponseListGetUserRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseListGetUserRes
   */
  message?: string;
  /**
   *
   * @type {Array<GetUserRes>}
   * @memberof BaseResponseListGetUserRes
   */
  result?: Array<GetUserRes>;
}
/**
 *
 * @export
 * @interface BaseResponsePostLoginRes
 */
export interface BaseResponsePostLoginRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostLoginRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostLoginRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostLoginRes
   */
  message?: string;
  /**
   *
   * @type {PostLoginRes}
   * @memberof BaseResponsePostLoginRes
   */
  result?: PostLoginRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentCancelRes
 */
export interface BaseResponsePostPaymentCancelRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentCancelRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentCancelRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentCancelRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentCancelRes}
   * @memberof BaseResponsePostPaymentCancelRes
   */
  result?: PostPaymentCancelRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentCardKeyRes
 */
export interface BaseResponsePostPaymentCardKeyRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentCardKeyRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentCardKeyRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentCardKeyRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentCardKeyRes}
   * @memberof BaseResponsePostPaymentCardKeyRes
   */
  result?: PostPaymentCardKeyRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentCompleteRes
 */
export interface BaseResponsePostPaymentCompleteRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentCompleteRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentCompleteRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentCompleteRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentCompleteRes}
   * @memberof BaseResponsePostPaymentCompleteRes
   */
  result?: PostPaymentCompleteRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentOneTimeRes
 */
export interface BaseResponsePostPaymentOneTimeRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentOneTimeRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentOneTimeRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentOneTimeRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentOneTimeRes}
   * @memberof BaseResponsePostPaymentOneTimeRes
   */
  result?: PostPaymentOneTimeRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentPrepareRes
 */
export interface BaseResponsePostPaymentPrepareRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentPrepareRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentPrepareRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentPrepareRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentPrepareRes}
   * @memberof BaseResponsePostPaymentPrepareRes
   */
  result?: PostPaymentPrepareRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostPaymentReservationRes
 */
export interface BaseResponsePostPaymentReservationRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostPaymentReservationRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostPaymentReservationRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostPaymentReservationRes
   */
  message?: string;
  /**
   *
   * @type {PostPaymentReservationRes}
   * @memberof BaseResponsePostPaymentReservationRes
   */
  result?: PostPaymentReservationRes;
}
/**
 *
 * @export
 * @interface BaseResponsePostUserRes
 */
export interface BaseResponsePostUserRes {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponsePostUserRes
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponsePostUserRes
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponsePostUserRes
   */
  message?: string;
  /**
   *
   * @type {PostUserRes}
   * @memberof BaseResponsePostUserRes
   */
  result?: PostUserRes;
}
/**
 *
 * @export
 * @interface BaseResponseString
 */
export interface BaseResponseString {
  /**
   *
   * @type {boolean}
   * @memberof BaseResponseString
   */
  isSuccess?: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseResponseString
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof BaseResponseString
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof BaseResponseString
   */
  result?: string;
}
/**
 *
 * @export
 * @interface GetMemoDto
 */
export interface GetMemoDto {
  /**
   *
   * @type {number}
   * @memberof GetMemoDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GetMemoDto
   */
  memo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetMemoDto
   */
  commentList?: Array<string>;
}
/**
 * @type GetSocialOAuthRes
 * @export
 */
export type GetSocialOAuthRes = OauthRes;

/**
 *
 * @export
 * @interface GetUserRes
 */
export interface GetUserRes {
  /**
   *
   * @type {number}
   * @memberof GetUserRes
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GetUserRes
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserRes
   */
  name?: string;
}
/**
 *
 * @export
 * @interface MemoDto
 */
export interface MemoDto {
  /**
   *
   * @type {string}
   * @memberof MemoDto
   */
  memo: string;
}
/**
 *
 * @export
 * @interface OauthRes
 */
export interface OauthRes {
  /**
   *
   * @type {string}
   * @memberof OauthRes
   */
  jwtToken?: string;
  /**
   *
   * @type {number}
   * @memberof OauthRes
   */
  userId?: number;
}
/**
 *
 * @export
 * @interface PatchUserReq
 */
export interface PatchUserReq {
  /**
   *
   * @type {string}
   * @memberof PatchUserReq
   */
  name?: string;
}
/**
 *
 * @export
 * @interface PostCommentDto
 */
export interface PostCommentDto {
  /**
   *
   * @type {number}
   * @memberof PostCommentDto
   */
  memoId?: number;
  /**
   *
   * @type {string}
   * @memberof PostCommentDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface PostLoginReq
 */
export interface PostLoginReq {
  /**
   *
   * @type {string}
   * @memberof PostLoginReq
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PostLoginReq
   */
  password?: string;
}
/**
 *
 * @export
 * @interface PostLoginRes
 */
export interface PostLoginRes {
  /**
   *
   * @type {number}
   * @memberof PostLoginRes
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PostLoginRes
   */
  jwt?: string;
}
/**
 *
 * @export
 * @interface PostPaymentCancelRes
 */
export interface PostPaymentCancelRes {
  /**
   *
   * @type {string}
   * @memberof PostPaymentCancelRes
   */
  payemntId?: string;
}
/**
 *
 * @export
 * @interface PostPaymentCardKeyReq
 */
export interface PostPaymentCardKeyReq {
  /**
   * 카드 결제로 사용하기 위한 유저 고유 ID
   * @type {string}
   * @memberof PostPaymentCardKeyReq
   */
  userId: string;
  /**
   * 카드번호(dddd-dddd-dddd-dddd)
   * @type {string}
   * @memberof PostPaymentCardKeyReq
   */
  cardNumber: string;
  /**
   * 카드 유효기간 (YYYY-MM)
   * @type {string}
   * @memberof PostPaymentCardKeyReq
   */
  expiry: string;
  /**
   * 생년월일 6자리
   * @type {string}
   * @memberof PostPaymentCardKeyReq
   */
  birth: string;
  /**
   * 카드 비밀번호 앞2자리
   * @type {string}
   * @memberof PostPaymentCardKeyReq
   */
  pwd2digit: string;
}
/**
 *
 * @export
 * @interface PostPaymentCardKeyRes
 */
export interface PostPaymentCardKeyRes {
  /**
   *
   * @type {string}
   * @memberof PostPaymentCardKeyRes
   */
  userId?: string;
}
/**
 *
 * @export
 * @interface PostPaymentCompleteReq
 */
export interface PostPaymentCompleteReq {
  /**
   * 결제Id (아임포트에서는 imp_uid)
   * @type {string}
   * @memberof PostPaymentCompleteReq
   */
  paymentId: string;
  /**
   * 주문Id (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentCompleteReq
   */
  orderId: string;
}
/**
 *
 * @export
 * @interface PostPaymentCompleteRes
 */
export interface PostPaymentCompleteRes {
  /**
   * 결제Id (아임포트에서는 imp_uid)
   * @type {string}
   * @memberof PostPaymentCompleteRes
   */
  paymentId?: string;
  /**
   * 주문Id (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentCompleteRes
   */
  orderId?: string;
  /**
   * 실제 결제된 금액
   * @type {number}
   * @memberof PostPaymentCompleteRes
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface PostPaymentOneTimeReq
 */
export interface PostPaymentOneTimeReq {
  /**
   * 주문Id (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentOneTimeReq
   */
  orderId: string;
  /**
   * 결제 금액
   * @type {number}
   * @memberof PostPaymentOneTimeReq
   */
  amount: number;
  /**
   * 카드 키로 결제하기 위한 유저 아이디(사전에 카드키가 등록 되어야 함)
   * @type {string}
   * @memberof PostPaymentOneTimeReq
   */
  userId: string;
  /**
   * 주문명
   * @type {string}
   * @memberof PostPaymentOneTimeReq
   */
  orderName: string;
}
/**
 *
 * @export
 * @interface PostPaymentOneTimeRes
 */
export interface PostPaymentOneTimeRes {
  /**
   *
   * @type {string}
   * @memberof PostPaymentOneTimeRes
   */
  paymentId?: string;
}
/**
 *
 * @export
 * @interface PostPaymentPrepareReq
 */
export interface PostPaymentPrepareReq {
  /**
   * 주문ID (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentPrepareReq
   */
  orderId: string;
  /**
   * 검증할 가격
   * @type {number}
   * @memberof PostPaymentPrepareReq
   */
  amount: number;
}
/**
 *
 * @export
 * @interface PostPaymentPrepareRes
 */
export interface PostPaymentPrepareRes {
  /**
   *
   * @type {string}
   * @memberof PostPaymentPrepareRes
   */
  orderId?: string;
  /**
   *
   * @type {number}
   * @memberof PostPaymentPrepareRes
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface PostPaymentReservationReq
 */
export interface PostPaymentReservationReq {
  /**
   * 카드 키로 결제하기 위한 유저 아이디(사전에 카드키가 등록 되어야 함)
   * @type {string}
   * @memberof PostPaymentReservationReq
   */
  userId: string;
  /**
   * 결제를 예약하기 위한 스케쥴 정보
   * @type {Array<PostPaymentScheduleReq>}
   * @memberof PostPaymentReservationReq
   */
  schedules: Array<PostPaymentScheduleReq>;
}
/**
 *
 * @export
 * @interface PostPaymentReservationRes
 */
export interface PostPaymentReservationRes {
  /**
   * 카드 키로 결제하기 위한 유저 아이디(사전에 카드키가 등록 되어야 함)
   * @type {string}
   * @memberof PostPaymentReservationRes
   */
  userId?: string;
  /**
   * 결제를 예약하기 위한 스케쥴 정보
   * @type {Array<PostPaymentScheduleRes>}
   * @memberof PostPaymentReservationRes
   */
  schedules?: Array<PostPaymentScheduleRes>;
}
/**
 * 결제를 예약하기 위한 스케쥴 정보
 * @export
 * @interface PostPaymentScheduleReq
 */
export interface PostPaymentScheduleReq {
  /**
   * 주문Id (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentScheduleReq
   */
  orderId: string;
  /**
   * 결제 금액
   * @type {number}
   * @memberof PostPaymentScheduleReq
   */
  amount: number;
  /**
   * 결제 예약일시(yyyy-MM-dd HH:mm:ss)
   * @type {string}
   * @memberof PostPaymentScheduleReq
   */
  scheduledAt: string;
  /**
   * 주문명
   * @type {string}
   * @memberof PostPaymentScheduleReq
   */
  name: string;
}
/**
 * 결제를 예약하기 위한 스케쥴 정보
 * @export
 * @interface PostPaymentScheduleRes
 */
export interface PostPaymentScheduleRes {
  /**
   * 주문Id (아임포트에서는 merchant_uid)
   * @type {string}
   * @memberof PostPaymentScheduleRes
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof PostPaymentScheduleRes
   */
  paymentId?: string;
  /**
   * 결제 금액
   * @type {number}
   * @memberof PostPaymentScheduleRes
   */
  amount?: number;
  /**
   * 결제 예약일시(yyyy-MM-dd HH:mm:ss)
   * @type {string}
   * @memberof PostPaymentScheduleRes
   */
  scheduledAt?: string;
}
/**
 *
 * @export
 * @interface PostUserReq
 */
export interface PostUserReq {
  /**
   *
   * @type {string}
   * @memberof PostUserReq
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PostUserReq
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof PostUserReq
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof PostUserReq
   */
  oauth?: boolean;
}
/**
 *
 * @export
 * @interface PostUserRes
 */
export interface PostUserRes {
  /**
   *
   * @type {number}
   * @memberof PostUserRes
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PostUserRes
   */
  jwt?: string;
}

/**
 * HealthControllerApi - axios parameter creator
 * @export
 */
export const HealthControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthControllerApi - functional programming interface
 * @export
 */
export const HealthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HealthControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthCheck(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.healthCheck(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HealthControllerApi.healthCheck']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HealthControllerApi - factory interface
 * @export
 */
export const HealthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return localVarFp
        .healthCheck(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthControllerApi - object-oriented interface
 * @export
 * @class HealthControllerApi
 * @extends {BaseAPI}
 */
export class HealthControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthControllerApi
   */
  public healthCheck(options?: RawAxiosRequestConfig) {
    return HealthControllerApiFp(this.configuration)
      .healthCheck(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 결제한 금액을 전체 취소 한다.
     * @summary 결제 취소
     * @param {string} id 결제한 paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancel', 'id', id);
      const localVarPath = `/app/payments/{id}/cancel`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 결제 후 가격을 검증
     * @summary 결제 후 검증
     * @param {PostPaymentCompleteReq} postPaymentCompleteReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    complete: async (
      postPaymentCompleteReq: PostPaymentCompleteReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPaymentCompleteReq' is not null or undefined
      assertParamExists(
        'complete',
        'postPaymentCompleteReq',
        postPaymentCompleteReq,
      );
      const localVarPath = `/app/payments/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPaymentCompleteReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 카드 정보를 재활용 하기위한 api
     * @summary 카드 키 발급
     * @param {PostPaymentCardKeyReq} postPaymentCardKeyReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardKey: async (
      postPaymentCardKeyReq: PostPaymentCardKeyReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPaymentCardKeyReq' is not null or undefined
      assertParamExists(
        'createCardKey',
        'postPaymentCardKeyReq',
        postPaymentCardKeyReq,
      );
      const localVarPath = `/app/payments/card-key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPaymentCardKeyReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 발급된 카드키 만으로 결제
     * @summary 단건 결제
     * @param {PostPaymentOneTimeReq} postPaymentOneTimeReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oneTime: async (
      postPaymentOneTimeReq: PostPaymentOneTimeReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPaymentOneTimeReq' is not null or undefined
      assertParamExists(
        'oneTime',
        'postPaymentOneTimeReq',
        postPaymentOneTimeReq,
      );
      const localVarPath = `/app/payments/one-time`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPaymentOneTimeReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 결제 전에 가격을 검증하기 위해 서버에 등록
     * @summary 결제 사전 검증
     * @param {PostPaymentPrepareReq} postPaymentPrepareReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preparePayments: async (
      postPaymentPrepareReq: PostPaymentPrepareReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPaymentPrepareReq' is not null or undefined
      assertParamExists(
        'preparePayments',
        'postPaymentPrepareReq',
        postPaymentPrepareReq,
      );
      const localVarPath = `/app/payments/prepare`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPaymentPrepareReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 스케쥴 된 일자에 결제를 실행
     * @summary 예약 결제
     * @param {PostPaymentReservationReq} postPaymentReservationReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservations: async (
      postPaymentReservationReq: PostPaymentReservationReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPaymentReservationReq' is not null or undefined
      assertParamExists(
        'reservations',
        'postPaymentReservationReq',
        postPaymentReservationReq,
      );
      const localVarPath = `/app/payments/reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postPaymentReservationReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration);
  return {
    /**
     * 결제한 금액을 전체 취소 한다.
     * @summary 결제 취소
     * @param {string} id 결제한 paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancel(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentCancelRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(
        id,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.cancel']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 결제 후 가격을 검증
     * @summary 결제 후 검증
     * @param {PostPaymentCompleteReq} postPaymentCompleteReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async complete(
      postPaymentCompleteReq: PostPaymentCompleteReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentCompleteRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.complete(
        postPaymentCompleteReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.complete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 카드 정보를 재활용 하기위한 api
     * @summary 카드 키 발급
     * @param {PostPaymentCardKeyReq} postPaymentCardKeyReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCardKey(
      postPaymentCardKeyReq: PostPaymentCardKeyReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentCardKeyRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCardKey(
        postPaymentCardKeyReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.createCardKey']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 발급된 카드키 만으로 결제
     * @summary 단건 결제
     * @param {PostPaymentOneTimeReq} postPaymentOneTimeReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oneTime(
      postPaymentOneTimeReq: PostPaymentOneTimeReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentOneTimeRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oneTime(
        postPaymentOneTimeReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.oneTime']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 결제 전에 가격을 검증하기 위해 서버에 등록
     * @summary 결제 사전 검증
     * @param {PostPaymentPrepareReq} postPaymentPrepareReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async preparePayments(
      postPaymentPrepareReq: PostPaymentPrepareReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentPrepareRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.preparePayments(
        postPaymentPrepareReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.preparePayments']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 스케쥴 된 일자에 결제를 실행
     * @summary 예약 결제
     * @param {PostPaymentReservationReq} postPaymentReservationReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reservations(
      postPaymentReservationReq: PostPaymentReservationReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostPaymentReservationRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reservations(
        postPaymentReservationReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PaymentsApi.reservations']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentsApiFp(configuration);
  return {
    /**
     * 결제한 금액을 전체 취소 한다.
     * @summary 결제 취소
     * @param {string} id 결제한 paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancel(
      id: string,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentCancelRes> {
      return localVarFp
        .cancel(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 결제 후 가격을 검증
     * @summary 결제 후 검증
     * @param {PostPaymentCompleteReq} postPaymentCompleteReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    complete(
      postPaymentCompleteReq: PostPaymentCompleteReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentCompleteRes> {
      return localVarFp
        .complete(postPaymentCompleteReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 카드 정보를 재활용 하기위한 api
     * @summary 카드 키 발급
     * @param {PostPaymentCardKeyReq} postPaymentCardKeyReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardKey(
      postPaymentCardKeyReq: PostPaymentCardKeyReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentCardKeyRes> {
      return localVarFp
        .createCardKey(postPaymentCardKeyReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 발급된 카드키 만으로 결제
     * @summary 단건 결제
     * @param {PostPaymentOneTimeReq} postPaymentOneTimeReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oneTime(
      postPaymentOneTimeReq: PostPaymentOneTimeReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentOneTimeRes> {
      return localVarFp
        .oneTime(postPaymentOneTimeReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 결제 전에 가격을 검증하기 위해 서버에 등록
     * @summary 결제 사전 검증
     * @param {PostPaymentPrepareReq} postPaymentPrepareReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preparePayments(
      postPaymentPrepareReq: PostPaymentPrepareReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentPrepareRes> {
      return localVarFp
        .preparePayments(postPaymentPrepareReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 스케쥴 된 일자에 결제를 실행
     * @summary 예약 결제
     * @param {PostPaymentReservationReq} postPaymentReservationReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservations(
      postPaymentReservationReq: PostPaymentReservationReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostPaymentReservationRes> {
      return localVarFp
        .reservations(postPaymentReservationReq, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   * 결제한 금액을 전체 취소 한다.
   * @summary 결제 취소
   * @param {string} id 결제한 paymentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public cancel(id: string, options?: RawAxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .cancel(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 결제 후 가격을 검증
   * @summary 결제 후 검증
   * @param {PostPaymentCompleteReq} postPaymentCompleteReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public complete(
    postPaymentCompleteReq: PostPaymentCompleteReq,
    options?: RawAxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .complete(postPaymentCompleteReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 카드 정보를 재활용 하기위한 api
   * @summary 카드 키 발급
   * @param {PostPaymentCardKeyReq} postPaymentCardKeyReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public createCardKey(
    postPaymentCardKeyReq: PostPaymentCardKeyReq,
    options?: RawAxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .createCardKey(postPaymentCardKeyReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 발급된 카드키 만으로 결제
   * @summary 단건 결제
   * @param {PostPaymentOneTimeReq} postPaymentOneTimeReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public oneTime(
    postPaymentOneTimeReq: PostPaymentOneTimeReq,
    options?: RawAxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .oneTime(postPaymentOneTimeReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 결제 전에 가격을 검증하기 위해 서버에 등록
   * @summary 결제 사전 검증
   * @param {PostPaymentPrepareReq} postPaymentPrepareReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public preparePayments(
    postPaymentPrepareReq: PostPaymentPrepareReq,
    options?: RawAxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .preparePayments(postPaymentPrepareReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 스케쥴 된 일자에 결제를 실행
   * @summary 예약 결제
   * @param {PostPaymentReservationReq} postPaymentReservationReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public reservations(
    postPaymentReservationReq: PostPaymentReservationReq,
    options?: RawAxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .reservations(postPaymentReservationReq, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * 입력 받은 메모에 받아온 문자열로 코멘트를 생성합니다.
     * @summary 코멘트 생성
     * @param {PostCommentDto} postCommentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment: async (
      postCommentDto: PostCommentDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postCommentDto' is not null or undefined
      assertParamExists('createComment', 'postCommentDto', postCommentDto);
      const localVarPath = `/test/comments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postCommentDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 문자열을 받아 메모를 생성합니다.
     * @summary 메모 생성
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMemo: async (
      memoDto: MemoDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoDto' is not null or undefined
      assertParamExists('createMemo', 'memoDto', memoDto);
      const localVarPath = `/test/memos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 저장된 메모 리스트를 조회합니다.
     * @summary 메모 리스트 조회
     * @param {number} startPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMemos: async (
      startPage: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startPage' is not null or undefined
      assertParamExists('getMemos', 'startPage', startPage);
      const localVarPath = `/test/memos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startPage !== undefined) {
        localVarQueryParameter['startPage'] = startPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logTest: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 입력받은 메모 id의 메모를 받아온 문자열 값으로 변경합니다.
     * @summary 메모 정보 변경
     * @param {number} memoId
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyMemo: async (
      memoId: number,
      memoDto: MemoDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoId' is not null or undefined
      assertParamExists('modifyMemo', 'memoId', memoId);
      // verify required parameter 'memoDto' is not null or undefined
      assertParamExists('modifyMemo', 'memoDto', memoDto);
      const localVarPath = `/test/memos/{memoId}`.replace(
        `{${'memoId'}}`,
        encodeURIComponent(String(memoId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration);
  return {
    /**
     * 입력 받은 메모에 받아온 문자열로 코멘트를 생성합니다.
     * @summary 코멘트 생성
     * @param {PostCommentDto} postCommentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createComment(
      postCommentDto: PostCommentDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(
        postCommentDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TestApi.createComment']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 문자열을 받아 메모를 생성합니다.
     * @summary 메모 생성
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMemo(
      memoDto: MemoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMemo(
        memoDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TestApi.createMemo']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 저장된 메모 리스트를 조회합니다.
     * @summary 메모 리스트 조회
     * @param {number} startPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMemos(
      startPage: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListGetMemoDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMemos(
        startPage,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TestApi.getMemos']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logTest(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.logTest(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TestApi.logTest']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * 입력받은 메모 id의 메모를 받아온 문자열 값으로 변경합니다.
     * @summary 메모 정보 변경
     * @param {number} memoId
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modifyMemo(
      memoId: number,
      memoDto: MemoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.modifyMemo(
        memoId,
        memoDto,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TestApi.modifyMemo']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TestApiFp(configuration);
  return {
    /**
     * 입력 받은 메모에 받아온 문자열로 코멘트를 생성합니다.
     * @summary 코멘트 생성
     * @param {PostCommentDto} postCommentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(
      postCommentDto: PostCommentDto,
      options?: any,
    ): AxiosPromise<BaseResponseString> {
      return localVarFp
        .createComment(postCommentDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 문자열을 받아 메모를 생성합니다.
     * @summary 메모 생성
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMemo(
      memoDto: MemoDto,
      options?: any,
    ): AxiosPromise<BaseResponseString> {
      return localVarFp
        .createMemo(memoDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 저장된 메모 리스트를 조회합니다.
     * @summary 메모 리스트 조회
     * @param {number} startPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMemos(
      startPage: number,
      options?: any,
    ): AxiosPromise<BaseResponseListGetMemoDto> {
      return localVarFp
        .getMemos(startPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logTest(options?: any): AxiosPromise<string> {
      return localVarFp
        .logTest(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 입력받은 메모 id의 메모를 받아온 문자열 값으로 변경합니다.
     * @summary 메모 정보 변경
     * @param {number} memoId
     * @param {MemoDto} memoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyMemo(
      memoId: number,
      memoDto: MemoDto,
      options?: any,
    ): AxiosPromise<BaseResponseString> {
      return localVarFp
        .modifyMemo(memoId, memoDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
  /**
   * 입력 받은 메모에 받아온 문자열로 코멘트를 생성합니다.
   * @summary 코멘트 생성
   * @param {PostCommentDto} postCommentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public createComment(
    postCommentDto: PostCommentDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TestApiFp(this.configuration)
      .createComment(postCommentDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 문자열을 받아 메모를 생성합니다.
   * @summary 메모 생성
   * @param {MemoDto} memoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public createMemo(memoDto: MemoDto, options?: RawAxiosRequestConfig) {
    return TestApiFp(this.configuration)
      .createMemo(memoDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 저장된 메모 리스트를 조회합니다.
   * @summary 메모 리스트 조회
   * @param {number} startPage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public getMemos(startPage: number, options?: RawAxiosRequestConfig) {
    return TestApiFp(this.configuration)
      .getMemos(startPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public logTest(options?: RawAxiosRequestConfig) {
    return TestApiFp(this.configuration)
      .logTest(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 입력받은 메모 id의 메모를 받아온 문자열 값으로 변경합니다.
   * @summary 메모 정보 변경
   * @param {number} memoId
   * @param {MemoDto} memoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public modifyMemo(
    memoId: number,
    memoDto: MemoDto,
    options?: RawAxiosRequestConfig,
  ) {
    return TestApiFp(this.configuration)
      .modifyMemo(memoId, memoDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {PostUserReq} postUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      postUserReq: PostUserReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postUserReq' is not null or undefined
      assertParamExists('createUser', 'postUserReq', postUserReq);
      const localVarPath = `/app/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postUserReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (
      userId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('deleteUser', 'userId', userId);
      const localVarPath = `/app/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      userId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUser', 'userId', userId);
      const localVarPath = `/app/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (
      email?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/app/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (email !== undefined) {
        localVarQueryParameter['Email'] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostLoginReq} postLoginReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logIn: async (
      postLoginReq: PostLoginReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postLoginReq' is not null or undefined
      assertParamExists('logIn', 'postLoginReq', postLoginReq);
      const localVarPath = `/app/users/logIn`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postLoginReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {PatchUserReq} patchUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyUserName: async (
      userId: number,
      patchUserReq: PatchUserReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('modifyUserName', 'userId', userId);
      // verify required parameter 'patchUserReq' is not null or undefined
      assertParamExists('modifyUserName', 'patchUserReq', patchUserReq);
      const localVarPath = `/app/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchUserReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SocialLoginSocialLoginTypeEnum} socialLoginType
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    socialLogin: async (
      socialLoginType: SocialLoginSocialLoginTypeEnum,
      code: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'socialLoginType' is not null or undefined
      assertParamExists('socialLogin', 'socialLoginType', socialLoginType);
      // verify required parameter 'code' is not null or undefined
      assertParamExists('socialLogin', 'code', code);
      const localVarPath = `/app/users/auth/{socialLoginType}/login`.replace(
        `{${'socialLoginType'}}`,
        encodeURIComponent(String(socialLoginType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PostUserReq} postUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      postUserReq: PostUserReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostUserRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        postUserReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.createUser']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      userId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(
        userId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.deleteUser']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      userId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetUserRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        userId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.getUser']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      email?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseListGetUserRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(
        email,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.getUsers']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {PostLoginReq} postLoginReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logIn(
      postLoginReq: PostLoginReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponsePostLoginRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logIn(
        postLoginReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.logIn']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {PatchUserReq} patchUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modifyUserName(
      userId: number,
      patchUserReq: PatchUserReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.modifyUserName(
        userId,
        patchUserReq,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.modifyUserName']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {SocialLoginSocialLoginTypeEnum} socialLoginType
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async socialLogin(
      socialLoginType: SocialLoginSocialLoginTypeEnum,
      code: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BaseResponseGetSocialOAuthRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.socialLogin(
        socialLoginType,
        code,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserControllerApi.socialLogin']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @param {PostUserReq} postUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      postUserReq: PostUserReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostUserRes> {
      return localVarFp
        .createUser(postUserReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(
      userId: number,
      options?: any,
    ): AxiosPromise<BaseResponseString> {
      return localVarFp
        .deleteUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(
      userId: number,
      options?: any,
    ): AxiosPromise<BaseResponseGetUserRes> {
      return localVarFp
        .getUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      email?: string,
      options?: any,
    ): AxiosPromise<BaseResponseListGetUserRes> {
      return localVarFp
        .getUsers(email, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostLoginReq} postLoginReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logIn(
      postLoginReq: PostLoginReq,
      options?: any,
    ): AxiosPromise<BaseResponsePostLoginRes> {
      return localVarFp
        .logIn(postLoginReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {PatchUserReq} patchUserReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyUserName(
      userId: number,
      patchUserReq: PatchUserReq,
      options?: any,
    ): AxiosPromise<BaseResponseString> {
      return localVarFp
        .modifyUserName(userId, patchUserReq, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SocialLoginSocialLoginTypeEnum} socialLoginType
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    socialLogin(
      socialLoginType: SocialLoginSocialLoginTypeEnum,
      code: string,
      options?: any,
    ): AxiosPromise<BaseResponseGetSocialOAuthRes> {
      return localVarFp
        .socialLogin(socialLoginType, code, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @param {PostUserReq} postUserReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public createUser(postUserReq: PostUserReq, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .createUser(postUserReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public deleteUser(userId: number, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .deleteUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUser(userId: number, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUsers(email?: string, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUsers(email, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostLoginReq} postLoginReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public logIn(postLoginReq: PostLoginReq, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .logIn(postLoginReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {PatchUserReq} patchUserReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public modifyUserName(
    userId: number,
    patchUserReq: PatchUserReq,
    options?: RawAxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .modifyUserName(userId, patchUserReq, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SocialLoginSocialLoginTypeEnum} socialLoginType
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public socialLogin(
    socialLoginType: SocialLoginSocialLoginTypeEnum,
    code: string,
    options?: RawAxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .socialLogin(socialLoginType, code, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const SocialLoginSocialLoginTypeEnum = {
  Google: 'google',
  Kakao: 'kakao',
  Naver: 'naver',
} as const;
export type SocialLoginSocialLoginTypeEnum =
  (typeof SocialLoginSocialLoginTypeEnum)[keyof typeof SocialLoginSocialLoginTypeEnum];
